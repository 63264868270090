import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import styled from 'styled-components'
import {newUser} from '../actions/card'

const CHK = styled.img`
  position: absolute;
  width: 50px;
`;
const Addr = styled.input `
  padding: 4px 5px;
  font-size: 12px;
  margin: 4px;
  width: 67%;
  text-align: center;
  border: groove white;
  height: 22px;
`;
const POLR = styled.div`
  position: relative;
  width: 100%;
  color: black;
  font-size: 12px;
  margin-top: 4px;
  height: 270px;
`;
const INNER = styled.div`
  position: relative;
  width: 90%;
  margin: auto;
  top: 30px;
`;

export const Avat = () => {
  const dispatch = useDispatch()
  const avat = useSelector(state => state.post.avat)
  const lang = useSelector(state => state.post.lang)
  
  const [Checkit, setCheckit] = useState(-1)
  const [Code, setCode] = useState('')
  const [Name, setName] = useState('')
  const [Mail, setMail] = useState('')
  const [Year, setYear] = useState('')

  const check = <CHK src = {"/Users/0/check.png"} />

  let butt

  const imageClick = (n, code) => {
    setCheckit(() => n)
    setCode(() => code)
  }
  
  if (Name && Mail && Year && Code) {
    butt = <img 
            src = '/Users/0/up.png' 
            alt = ''
            onClick = {() => dispatch(newUser(Name, Mail, Year, Code))}
            style = {{cursor:'pointer'}}
           ></img>
  }
  let info
  if (lang === 1) {
    info = ["Name", "E-mail", "Birth", 'Choose Avatar']
  }
  else {
    info = ["Имя", "Электронная почта", "Год рождения", 'Выберете свой Аватар']
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{ ...style, display: "block", width: "36px", height: "36px", right: "-8px", zIndex:"1", border: "1px solid white", background: "white"}}
        onClick={onClick}
        src={"/Users/0/rt.png"}
        alt="up"
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{ ...style, display: "block", width: "36px", height: "36px", left: "-9px", zIndex:"1", border: "1px solid white", background: "white"}}
        onClick={onClick}
        src={"/Users/0/lt.png"}
        alt="dn"
      />
    );
  }
  var settings = {
    infinite: true,
    slidesToShow: 5,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  return (
    <div>
      <Addr type = "text"
            placeholder = {info[0]}
            onChange = {e => setName(e.target.value)}
      >
      </Addr>
      <Addr type = "text"
            placeholder = {info[1]}
            onChange  = {e => setMail(e.target.value)}
      >
      </Addr>
      <Addr type = "text"
            placeholder = {info[2]}
            onChange  =  {e => setYear(e.target.value)}
      >
      </Addr>
      <POLR>
      <INNER>
        <h3> {info[3]}</h3>
        <Slider {...settings}>
          {avat.map(poster => (
            <label key = {poster[0]}>
            <div id ="crew">
            {Checkit === poster[0] ? check : ""}
              <input
                type = "image"
                className="image"
                src={'/Users/0/' + poster[1] + 'p1.jpg'}
                alt= ''
                onClick = {() => imageClick(poster[0], poster[1])}
              />
            </div>
            </label>
          ))}
          </Slider>
        { butt }
      </INNER>
      </POLR>
    </div>
  )
}
export default (Avat)