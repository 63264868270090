import React, {useState, useEffect} from "react"
import StarRatingComponent from 'react-star-rating-component'
import {useDispatch, useSelector} from "react-redux";
import { text, setlang, setmode, file, putFold } from '../actions/card'

export const Coll = () => {
  const dispatch = useDispatch()
  const stam = useSelector(state => state.post.stam)
  const film = useSelector(state => state.post.post)
  const numb = useSelector(state => state.post.numb)
  const lang = useSelector(state => state.post.lang)
  const mode = useSelector(state => state.post.mode)
  const fileData = useSelector(state => state.post.fileData)
  const fileName = useSelector(state => state.post.fileName)
  const usernum = useSelector(state => state.post.usernum)
  const putfold = useSelector(state => state.post.putfold)

  let title

  const scrn = {
    background: 'url(/Users/0/tv.png) no-repeat',
    backgroundSize: '100% 99%',
    height: '291px'
  }
  const post = {
    float: 'left',
    width: '167px',
    marginLeft: '20px',
    marginTop: '15px'
  }
  const foto = {
    float: 'left',
    width: '360px',
    margin: '15px 10px 10px 22px',
    overflow: 'hidden',
    height: '220px'
  }
  const imgn = {
    float: 'left',
    width: '170px',
    height: '102px',
    marginLeft: '5px',
    overflow: 'hidden',
    border: 'black solid  1px'
  }
  const polr = {
    position: 'relative',
    width: '100%',
    color: 'black',
    fontSize: '12px',
    border: 'groove white',
    height: '280px',
    backgroundColor: 'white'
  }
  const innr = {
    position: 'relative',
    width: '90%',
    margin: 'auto',
    top: '40px'
  }
  const midl = {
    width: '100%',
    height: '120px',
    paddingTop: '10px'
  }
  const inpt = {
    float: 'left',
    margin: '3px',
    height: '28px',
    width: '28px',
    border: 'groove white',
    padding: '2.2px',
    background: 'white',
    cursor: 'pointer'
  }
  const addr = {
    padding: '4px 5px',
    fontSize: '16px',
    float: 'left',
    margin: '4px',
    width: '67%',
    textAlign: 'center',
    border: 'groove white',
    height: '22px'
  }
  const russ = {
    float: 'left',
    margin: '5px 3px 5px 3px',
    height: '30px',
    width: '30px',
    border: '1px solid white',
    padding: '2.2px',
    cursor: 'pointer'
  }

  const rew = film[numb][6][0]
  const rat = film[numb][6][1]
  //const [rewier, setRewier] = useState(1)
  const [Text, setText] = useState(rew)
  const [Star, setStar] = useState(rat)
  const [name, setName] = useState("")

  const type = (lett) => {
    dispatch(setmode(2))
    setName(lett)
  }

  if (mode === 0) {
    title = film[numb][5][lang]
  }
  if (mode === 1) {
    title = fileName
  }
  if (mode === 2) {
    title = name
  }

  const slang = (n) => {
      dispatch(setlang(n))
  }

  const send = (e) => {
      e.preventDefault()
      const data = new FormData()
      data.append("file", fileData);
      dispatch(file(data, usernum, -1, title))
      dispatch(setmode(0))
  }

  useEffect(() => {
    setText(() => rew)
    setStar(() => rat)
  }, [rew, rat]); 
  
  const sendText = (Text, nextValue) => {
    dispatch(text(stam, film[numb][1], Text, nextValue))
    setStar(() => nextValue)
  }

  const plac = <div><textarea name  = "" cols="65" rows="15"  
                           value = {Text}
                        onChange = {e => setText(e.target.value)}
                     ></textarea>
               </div>
  const star = <div>   
                <StarRatingComponent 
                            name = "rate"
                       className = { "Rating" }
                       starCount = { 5 }
                       starColor = { '#3b00ff' }
                  emptyStarColor = { '#819190' }
                     onStarClick = {(nextValue) => sendText(Text, nextValue)}
                         editing = {true}
                         value   = {Star}
                />
              </div>

  const pict = film[numb][2] + film[numb][1] + 'p2.jpg'
  const nums = [1, 2, 3, 4]
  const picts = <div>
                  {nums.map(num => (
                    <div style = {imgn}>  
                      <input type = "image"

                              src = {film[numb][2] + film[numb][1] + 'm' + num + '.jpg'}
                              alt = ''
                      />
                    </div>
                  ))}
                </div> 

  //const list = ['Отзыв', 'Оценки'] 
  //const buts = <div style = {panl}>
  //               <button  onClick = {() => setRewier(1)}>{list[0]}</button> 
  //               <button  onClick = {() => setRewier(2)}>{list[1]}</button>
  //             </div>
  const love = <div><input type = "image" 
                            src = '/Users/0/h.png'
                            onClick = { () => dispatch(putFold(stam, putfold, film[numb][1])) }
                            alt = ''
                    ></input></div>

  const form =  <>  <input style = {russ}
                             type = "image"
                              src = '/Users/0/rus.png'
                              alt = ''
                          onClick = { () => slang(0) }
                    />
                    <input style = {inpt}
                            type = "image"
                             src = '/Users/0/del.png'
                             alt = ''
                         onClick = { () => type("") } />
                    <form onSubmit = { send }>
                      <input style = {addr}
                              type = "text"
                             value = {title}
                               alt = ''
                          onChange = {e => type(e.target.value)}
                      />
                      <input style = {inpt}
                             type  = "image"
                               src = '/Users/0/rt.png'
                               alt = ''
                      />
                    </form>
                    <input style = {russ}
                            type = "image"
                             src = '/Users/0/eng.png'
                             alt = ''
                         onClick = { () => slang(1) }
                    />
                 </> 
  return (
      <><div style = {scrn}>
          <img style = {post}
                 src = {pict}
                 alt = ''
          />
          <div style = {foto}>
            {picts}
          </div>
            {putfold ? love : ""}
          </div>
          {form}
          <button>{film[numb][5][2]}</button>
          <div style = {midl}>
          {star}
        </div>  
        <div style = {polr}><div style = {innr}>{plac}</div></div>
      </>
  )
}
export default (Coll)