import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {pic, frnd} from '../actions/card'
import Scan from './Scan'

export const User = () => {
  const dispatch = useDispatch()
  const dnside  = useSelector(state => state.post.dnside)
  const bill    = useSelector(state => state.post.bill)
  const user    = useSelector(state => state.post.stam)
  const picters = useSelector(state => state.post.picts)
  const showPik = useSelector(state => state.post.showPik)
  const person  = useSelector(state => state.post.person)

  const scrn = {
    background: 'url(/Users/0/tv.png) no-repeat',
    backgroundSize: '100% 99%',
    height: '291px'
  }
  const post = {
    float: 'left',
    width: '167px',
    marginLeft: '20px',
    marginTop: '15px'
  }
  const midl = {
    width: '100%',
    height: '120px',
    paddingTop: '10px'
  }
  const foto = {
    float: 'left',
    width: '360px',
    margin: '15px 10px 10px 22px',
    overflow: 'hidden',
    height: '220px'
  }
  const panl = {
    width: '100%',
    height: '67px'
  }  
  const polr = {
    position: 'relative',
    width: '100%',
    color: 'black',
    fontSize: '12px',
    marginTop: '4px',
    background: 'url(/Users/0/tv.png) no-repeat',
    height: '300px',
    backgroundSize: '100%95%'
  }
  const innr = {
    position: 'relative',
    width: '90%',
    margin: 'auto',
    top: '40px'
  }
  const kads = {
    width: '332px',
    marginLeft: '10px'
  }
  const butn = {
    width: '58px',
    marginLeft: '4px',
    marginTop: '7px',
    marginBottom: '4px'
  }
  const imgn = {
    float: 'left',
    width: '170px',
    height: '102px',
    marginLeft: '5px',
    overflow: 'hidden',
    border: 'black solid  1px'
  }

  let pict, old, buts, love, picts, placard

  const getPik = (n) => {
    dispatch(pic(n))
  }

  if (dnside === 1) {
    if (showPik === 0) {
      love = <input type = "image" 
                     src = '/Users/0/del.png'
                 onClick = {() => dispatch(frnd(person[0], person[3], dnside, person[1], person[2], user))}
                     alt = ''
             ></input>
    }
  }
  if (dnside === 2) {
    if (showPik === 0) {
      love = <input type = "image" 
                     src = '/Users/0/h.png'    
                 onClick = {() => dispatch(frnd(person[0], person[3], dnside, person[1], person[2], user))}
                     alt = ''
             ></input>
    }
  }

  pict = '/Users/' + person[0] + 'p2.jpg'  
  old  = new Date().getFullYear() - person[2]
      
  if (showPik === 0) {
    picts = <div>{picters.map(imags => (
              <div style = {imgn}>
                <input type = "image"
                    onClick = {() => getPik(imags[0])}
                        src = {'Users/' + imags[5] + '/' + imags[1] + imags[2]}
                        alt = {imags[1]}
                ></input>
              </div> 
             ))}
            </div>
  }
  if (showPik !== 0) {
    picts = <input type = "image"
                  style = {kads} 
                onClick = {() => getPik(0)}
                    src = {'Users/' + person[3] + '/' + picters[showPik - 1][1] + picters[showPik - 1][3]}
                    alt = ''
            ></input>
  }

  if (bill) {
    buts = <div style = {panl}>
            {bill.map(but => (
              <img style = {butn}
                     src = {'/Users/0/'+ but +'.png'}
                     alt = ''
              />))} 
           </div>
  } 
  return (
    <><div style = {scrn}>
	      <img style = {post}
               src = {pict}
               alt = ''
        />
        <div style = {foto}>
          {picts}
        </div>
        {love}
      </div>
      <Scan/>
      <button>{old}</button>
      <div style = {midl}>
        {buts}
      </div>  
      <div style = {polr}><div style = {innr}>{placard}</div></div>
    </>
  )
}
export default (User)