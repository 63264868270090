import React from "react";
import { useSelector } from "react-redux"

export const Text = () => {
  const text = useSelector(state => state.post.text)
  const lang = useSelector(state => state.post.lang)
  let info

  const head = {
    border: "groove white",
    backgroundColor: "white"
  }
  const news = {
    fontSize: "18px",
    padding: "9px"
  }

  if (lang === 1) {
    info = text[0]
  }
  else {
    info = text[1]
  }

  return (
    <div style = {head}>
      <div style = {news}>
        {info}
      </div> 
    </div>
  )
}
export default (Text)