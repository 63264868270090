import React, {useState} from "react"
import {Provider} from 'react-redux'
import './App.css'
import store from './store'
import Side from './components/Side'
import Head from './components/Head'
import Form from './components/Form'
import Text from './components/Text'

//const socket = require("socket.io-client")("http://localhost:5000", { transports: ['polling'] })
const socket = require("socket.io-client")("https://www.gorisond.online", { transports: ['polling'] })

socket.on("connect_error", (err) => { console.log(`connect_error due to ${err.message}`)})

export const App = () => {

  const [Click, setClick] = useState(2)
  const pict = [["morning", 0], ["day", 1], ["night", 2], ["tunnel", 3], ["winter", 4]]

  const back = {
    backgroundImage: "url(/Users/0/lm-"+ pict[Click][0] + ".png)",
    backgroundSize: "100% 100%",
    "@media (maxWidth: 480px)": {
      width: "960px"
    }
  }
  const head = {
    height: "73px",
    padding: "3px 0px",
    border: "groove white",
    backgroundColor: "white"
  }
  const main = {
    width: "960px",
    marginLeft: "auto",
    marginRight: "auto"
  }
  const butt = {
    margin: "-4px 12px 7px 63px",
    float: "left",
    cursor: "pointer",
    border: "1px solid white",
  }
  
  return (
    <Provider store={store}>
      <div style = {main}>
          <Head socket={socket}/>
          <div style={{ height: "390px" }}>
            <Form side={1} socket={socket}/>
          </div>
          <Text/>
          <div style = {back}>  
            <div style={{ height: "777px" }}>
              <Side side={1} socket={socket}/> 
              <Form side={2} socket={socket}/>
              <Side side={2} socket={socket}/>
            </div>
          </div> 
          <div style = {head}>
            {pict.map(pic => (
              <label key = {pic[0]}>
              <img style   = {butt}
                   src     = {"/Users/0/sm-"+ pic[0] + ".png"}
                   alt     = 'butt'
                   onClick = {() => setClick(pic[1])}
              />
              </label>
            ))}
            <div> ©Hakimov Marat - gorisond@kshisa.ru </div>
          </div>
      </div>
    </Provider>
  )
}

export default (App)
